<template>
  <div class="page-sub-box sub_new_style01 sub_ui_box1">
    <!--"sub_new_style01" class적용시 상단라인 삭제-->
    <!-- 검색영역 -->
    <div class="page_search_box line_bottom_1px">
      <div class="inner clearfix">
        <div>
          <DxSelectBox
            placeholder="전체"
            :items="selectBoxData.forecastCycle"
            display-expr="name"
            value-expr="id"
            v-model="forecastCycle"
            :styling-mode="stylingMode"
            :show-clear-button="true"
            :input-attr="{ style: 'text-align: center;' }"
            :width="100"
            :height="34"
          />
          <span class="px-5"></span>
          <DxSelectBox
            placeholder="전체"
            :items="selectBoxData.modelType"
            display-expr="codeNm"
            value-expr="id"
            v-model="modelType"
            :styling-mode="stylingMode"
            :show-clear-button="true"
            :input-attr="{ style: 'text-align: center;' }"
            :width="200"
            :height="34"
          />
          <span class="px-5"></span>
          <DxButton text="검색" class="btn_M box-btn-search" type="button" :height="34" @click="onSearch" />
        </div>
      </div>
    </div>
    <!-- 검색영역 -->

    <!-- 모델 리스트 영역 -->
    <div class="cusmain-table-wrap">
      <esp-dx-data-grid :data-grid="dataGrid1" ref="dataGrid1" />
    </div>
    <!-- 모델 리스트 영역 -->
  </div>
</template>

<script>
  import Query from 'devextreme/data/query';

  import { DxToolbarItem } from 'devextreme-vue/popup';
  import DxDateBox from 'devextreme-vue/date-box';
  import DxButton from 'devextreme-vue/button';
  import DxRadioGroup from 'devextreme-vue/radio-group';
  import { DxSelectBox } from 'devextreme-vue/select-box';
  import moment from 'moment/moment';
  import EspDxDataGrid from '@/components/devextreme/esp-dx-data-grid.vue';

  let vm = this;

  export default {
    components: {
      EspDxDataGrid,
      DxSelectBox,
      DxDateBox,
      DxButton,
      DxRadioGroup,
      DxToolbarItem,
    },
    data() {
      return {
        config: {},
        stylingMode: 'outlined', //outlined, underlined, filled
        selectBoxData: {
          forecastCycle: [
            { id: 0, name: '월별' },
            { id: 1, name: '일별' },
          ],
          modelType: [],
        },
        forecastCycle: null,
        modelType: null,
        dataGrid1: {
          refName: 'dataGrid1',
          allowColumnResizing: true, //컬럼 사이즈 허용
          showBorders: false, //border 유무
          showColumnHeaders: true, //컬럼 헤더 유무
          showColumnLines: true, //컬럼 세로선 유무
          showRowLines: true, //컬럼 가로선 유무
          rowAlternationEnabled: false,
          dataSource: [],
          // width: '990',     // 주석처리시 100%
          // height: '300',    // 주석처리시 100%
          apiActionNm: {},
          customEvent: {
            //그리드 컴포넌트의 이벤트를 해당 페이지에서 사용할 수 있도록 처리 [ 사용: true, 미사용: false(생략 가능) ]
            optionChanged: true,
            rowClick: true,
          },
          showActionButtons: {
            select: false,
            copy: false,
            delete: false,
            customButtons: [
              {
                widget: 'dxButton',
                options: {
                  icon: '',
                  text: '등록',
                  elementAttr: { class: 'btn_XS default filled add1' },
                  width: 60,
                  height: 30,
                  onClick() {
                    vm.onRegUpdateData();
                  },
                },
                location: 'before',
              },
            ],
          },
          grouping: {
            contextMenuEnabled: false,
            autoExpandAll: false,
            allowCollapsing: true,
            expandMode: 'rowClick', // rowClick or buttonClick
          },
          groupPanel: {
            visible: false,
          },
          columnChooser: {
            enabled: false, // 컬럼 Chooser 버튼 사용유무
          },
          loadPanel: {
            enabled: false, // 로딩바 표시 유무
          },
          sorting: {
            mode: 'multiple', // single multiple
          },
          remoteOperations: {
            // 서버사이드 여부
            filtering: false,
            sorting: false,
            grouping: false,
            paging: false,
          },
          paging: {
            // scrolling 미사용시만 적용됨
            enabled: false,
            pageSize: 10,
            pageIndex: 0, // 시작페이지
          },
          pager: {
            visible: false, //페이저 표시 여부
            showPageSizeSelector: false, //페이지 사이즈 선택버튼 표시 여부
            allowedPageSizes: [],
            displayMode: 'compact', //표시 모드 : ['full', 'compact']
            showInfo: false, //페이지 정보 표시 여부 : full인 경우만 사용 가능
            showNavigationButtons: true, //페이지 네비게이션(화살표) 버튼 표시 여부 : full인 경우만 사용 가능
          },
          filterRow: {
            visible: false,
          },
          headerFilter: {
            visible: false,
          },

          editing: {
            allowUpdating: false, // 저장, 취소 버튼을 없애고 싶으면 allowUpdating allowAdding 를 둘다 false 설정
            allowDeleting: false,
            allowAdding: false, // 추가 버튼을 없애고 싶으면 false설정
            mode: 'batch', //수정 모드: ['row', 'cell', 'batch']
            startEditAction: 'click', //셀 편집 상태로 변경 할 이벤트 타입 : ['click', 'dbclick'] / 'cell', 'batch' 모드인 경우에만 가능
            selectTextOnEditStart: true, //셀 수정시 텍스트 전체 선택 여부
          },
          selecting: {
            mode: 'single', //행 단일/멀티 선택 타입 : ['single', 'multiple']
            selectAllMode: 'allPages', //행 선택 허용 범위 : ['allPages', 'page']
            showCheckBoxesMode: 'none', //행 선택 모드 : ['none', 'onClick', 'onLongTap', 'always']
          },
          columns: [
            {
              caption: '예측 주기',
              dataField: 'period',
              // width: 120,
              height: 40,
              alignment: 'center', // left center right
              visible: true,
              allowEditing: false,
              allowSorting: true,
              sortOrder: 'none', // acs desc none
              allowHeaderFiltering: false,
              allowGrouping: false,
              fixed: false, // 컬럼 fix 시 사용
              fixedPosition: 'left', // left or right
              calculateCellValue: this.getPeriodNm,
            },
            {
              caption: '모델 유형',
              dataField: 'modelType.codeNm',
              // width: 150,
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: false,
              allowSorting: true,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
              fixed: false, // 컬럼 fix 시 사용
              fixedPosition: 'left', // left or right
              cellTemplate: (container, options) => {
                let aTag = document.createElement('a');
                aTag.innerText = options.value;
                aTag.addEventListener('click', () => {
                  vm.onRegUpdateData(options.data);
                });
                container.append(aTag);
              },
            },
            {
              caption: '사용여부',
              dataField: 'viewCd',
              // width: 120,
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: false,
              allowSorting: true,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
              fixed: false, // 컬럼 fix 시 사용
              fixedPosition: 'left', // left or right
              calculateCellValue: this.getViewNm,
            },
            {
              caption: '최근 수정자',
              dataField: 'editId',
              // width: 150,
              height: 40,
              alignment: 'center', // left center right
              visible: true,
              allowEditing: false,
              allowSorting: true,
              sortOrder: 'none', // acs desc none
              allowHeaderFiltering: false,
              allowGrouping: false,
              fixed: false, // 컬럼 fix 시 사용
              fixedPosition: 'left', // left or right
            },
            {
              caption: '최근 수정일시',
              dataField: 'editDt',
              // width: 150,
              height: 40,
              alignment: 'center', // left center right
              visible: true,
              allowEditing: false,
              allowSorting: true,
              sortOrder: 'none', // acs desc none
              allowHeaderFiltering: false,
              allowGrouping: false,
              fixed: false, // 컬럼 fix 시 사용
              fixedPosition: 'left', // left or right
              calculateCellValue: this.formatEditDt,
            },
            {
              caption: '등록자',
              dataField: 'regId',
              // width: 150,
              height: 40,
              alignment: 'center', // left center right
              visible: true,
              allowEditing: false,
              allowSorting: true,
              sortOrder: 'none', // acs desc none
              allowHeaderFiltering: false,
              allowGrouping: false,
              fixed: false, // 컬럼 fix 시 사용
              fixedPosition: 'left', // left or right
            },
            {
              caption: '등록일시',
              dataField: 'regDt',
              // width: 150,
              height: 40,
              alignment: 'center', // left center right
              visible: true,
              allowEditing: false,
              allowSorting: true,
              sortOrder: 'none', // acs desc none
              allowHeaderFiltering: false,
              allowGrouping: false,
              fixed: false, // 컬럼 fix 시 사용
              fixedPosition: 'left', // left or right
              calculateCellValue: this.formatRegDt,
            },
          ],
        },
        startDate: null,
        endDate: null,
      };
    },
    computed: {},
    methods: {
      formatRegDt(rowData) {
        if (rowData.regDt) return this.$_commonlib.formatDate(rowData.regDt, 'YYYYMMDDHHmmssSSS').format('YYYY.MM.DD HH:mm:ss');
        else return;
      },
      formatEditDt(rowData) {
        if (rowData.editDt) return this.$_commonlib.formatDate(rowData.editDt, 'YYYYMMDDHHmmssSSS').format('YYYY.MM.DD HH:mm:ss');
        else return;
      },
      getPeriodNm(rowData) {
        return this.selectBoxData.forecastCycle.find(item => item.id === rowData.period).name;
      },
      getViewNm(rowData) {
        let code = rowData.viewCd;
        if (code === this.$_getUsedCode.id) {
          return '사용';
        } else {
          return '미사용';
        }
      },
      async onRegUpdateData(data) {
        // onRegUpdateData(data) {
        let params;
        if (data) {
          params = {
            updateYn: true,
            period: data.period,
            modelType: data.modelType.id,
            viewCd: data.viewCd,
          };
        } else {
          params = {
            updateYn: false,
          };
        }
        this.$store.commit('setDetailParams', params);
        this.$router.push('/report/ewm/forecast-model-config/reg-update');
      },
      onSearch() {
        this.$_Toast('검색 기능 구현 예정', '메세지');
        return false;
      },
      async selectData(startdate, enddate) {
        let paramsData = {
          startdate: this.$_commonlib.formatDate(startdate, 'YYYY/MM/DD', 'YYYYMMDD'),
          enddate: this.$_commonlib.formatDate(enddate, 'YYYY/MM/DD', 'YYYYMMDD'),
        };

        let payload = {
          actionname: 'FORECAST_LIST_ALL',
          data: { params: paramsData },
          loading: true,
        };

        // let res = await this.CALL_API(payload);
        let res = await { data: { data: [], header: { totalCount: 0, resCode: 'success' } }, status: 200 };

        if (res.status === 200) {
          if (res.data.header.resCode === 'success') {
            /////////// 임시로 데이터 넣음. 실데이터 받아오는로직으로 변경해야함 //////////////
            res.data.data = [
              {
                period: 0,
                modelType: { id: 1105, codeNm: 'Deep Learning' },
                viewCd: 2,
                editId: 'hpmoon',
                editDt: '20230926124030',
                regId: 'hpmoon',
                regDt: '20230926124030',
              },
              {
                period: 1,
                modelType: { id: 1106, codeNm: 'Prophet' },
                viewCd: 2,
                editId: 'hpmoon',
                editDt: '20230926124030',
                regId: 'hpmoon',
                regDt: '20230926124030',
              },
              {
                period: 0,
                modelType: { id: 1104, codeNm: 'ARIMA' },
                viewCd: 1,
                editId: 'hpmoon',
                editDt: '20230926124030',
                regId: 'hpmoon',
                regDt: '20230926124030',
              },
              {
                period: 1,
                modelType: { id: 1105, codeNm: 'Deep Learning' },
                viewCd: 2,
                editId: 'hpmoon',
                editDt: '20230926124030',
                regId: 'hpmoon',
                regDt: '20230926124030',
              },
              {
                period: 1,
                modelType: { id: 1104, codeNm: 'ARIMA' },
                viewCd: 1,
                editId: 'hpmoon',
                editDt: '20230926124030',
                regId: 'hpmoon',
                regDt: '20230926124030',
              },
            ];
            res.data.header.totalCount = 5;
            /////////// 임시로 데이터 넣음. 실데이터 받아오는로직으로 변경해야함 //////////////
            this.$refs.dataGrid1.totalCount = res.data.header.totalCount;
            this.dataGrid1.dataSource = res.data.data;
          }
        }
      },
      creatData() {
        this.endDate = new Date();
        this.startDate = new Date();
        this.startDate.setDate(this.startDate.getDate() - 30);
        this.selectData(this.startDate, this.endDate);
      },
    },
    created() {
      this.creatData();
    },
    mounted() {
      this.selectBoxData.modelType = this.$_getCode('forecast_models');
      vm = this;
    },
  };
</script>

<style></style>

<style scoped>
  .page-sub-box.sub_new_style01.sub_ui_box1 {
    padding-bottom: 0 !important;
  }

  .mar_b0 {
    margin-bottom: 0;
  }

  .px-5 {
    padding: 0 5px;
  }
</style>
